@font-face {
  font-family: "Azo Sans";
  src: local("Azo Sans Regular"), local("Azo-Sans-Regular"),
    url("../static/fonts/AzoSans-Regular.woff2") format("woff2"),
    url("../static/fonts/AzoSans-Regular.woff") format("woff"),
    url("../static/fonts/AzoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans";
  src: local("Azo Sans Bold"), local("Azo-Sans-Bold"),
    url("../static/fonts/AzoSans-Bold.woff2") format("woff2"),
    url("../static/fonts/AzoSans-Bold.woff") format("woff"),
    url("../static/fonts/AzoSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Azo Sans";
  src: local("Azo Sans Light"), local("Azo-Sans-Light"),
    url("../static/fonts/AzoSans-Light.woff2") format("woff2"),
    url("../static/fonts/AzoSans-Light.woff") format("woff"),
    url("../static/fonts/AzoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

.App {
  background-color: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  font-family: "Azo Sans";
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  mask-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 1) 1%
  );
  -webkit-mask-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 1) 1%
  );
}

.AppHeader {
  width: 100%;
  height: 8vh;
  background: linear-gradient(#113d6a, #17889a);
  z-index: 100;
}

.AppBar {
  position: fixed;
  width: 100%;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 100;
}

.ToolBar {
  width: 90%;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}
.ToolBarInner {
  width: 100%;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;
}

.HeaderLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.LinkButtonInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.LinkButtonFirst {
  color: #fff;
  font-size: 140%;
  margin-right: 8px;
}

.LinkButtonSecond {
  color: #fff;
  font-size: 160%;
}

.AppBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 72vh;
  width: 100%;
  max-width: 1500px;
}

.AppBodyFirst {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 50%;
}

.AppBodyFirstInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 70%;
  max-width: 600px;
  margin-right: 5%;
  margin-top: 2%;
}

.AppBodyFirstTitle {
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 10%;
  font-size: 170%;
  color: #146483;
}

.AppBodyFirstText {
  font-size: 100%;
  margin-bottom: 5px;
  margin-right: 10%;
}

.AppBodyFirstLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  max-width: 300px;
  margin-right: 10%;
}
.MediumIcon {
  padding-top: 5px;
  color: #146483;
}

.AppBodySecond {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
}

.AppBodySecondInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 60%;
  margin-top: 10%;
}

.AppFooter {
  height: 20vh;
  width: 100%;
}

.FooterInner {
  height: 20vh;
  width: 100vw;
  display: grid;
  overflow: hidden;
}

.metamaskStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.metamaskLogo {
  z-index: 5;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
}

.metamaskLogoOuter {
  width: 46px;
  height: 46px;
}

.metamaskLogoBehind {
  z-index: 1;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  top: 13px;
  left: 13px;
  background-color: #168d91;
}

.BottomVawesFront {
  height: 100%;
  width: 100%;
  z-index: 50;
}
.BottomVawesBack {
  height: 100%;
  width: 100%;
  z-index: 10;
}

.Wordmark {
  height: 50%;
  cursor: pointer;
}

.NFTCards {
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 8px 8px;
}

.NFTCardBody {
  height: 15%;
  background-color: #ffffff;
}

.NFTCardFooter {
  height: 12%;
  background-color: #f0f0f0;
  border-radius: 8px 8px 8px 8px;
  bottom: 0;
}

.NFTCardBodyMain {
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 8px 8px;
}

.SpinningCard {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.SpinningCardBack {
  height: 100%;
  width: 100%;
  background-color: #146483;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SpinningCardFront {
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: auto;
}

.SpinningCardFrontInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CardLogo {
  height: 40%;
}

.CardTitle {
  text-align: center;
  font-size: 110%;
}
.CardPrice {
  font-size: 50%;
  color: #146483;
  text-align: center;
  margin-top: -13px;
  margin-bottom: 20px;
}

.StartGallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1000px;
}

.Gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120%;
  width: 100%;
  max-width: 1000px;
  z-index: 50;
}

.GalleryInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 1000px;
  z-index: 50;
  position: relative;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #146483 !important;
}

.GalleryCheckBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.Mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.GalleryCheckText {
  color: #146483;
}

.ListImage {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
}

.ListImageHide {
  border-radius: 5px 5px 0px 0px;
  display: none;
  width: 100%;
}

.ListImageRabbit {
  max-width: 400px;
}

.LoadingRabbit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 80%;
}

.LoadingRabbitLabel {
  margin-top: -50px;
  color: #146483;
  font-size: 120%;
  text-align: center;
}

.NftModal {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NftModalOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
}
.NftModalFooter {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background-color: #146483;
  color: #fff;
  border-radius: 8px;
  font-size: 80%;
}

.NftModalFooterText {
  font-size: 90%;
  margin-left: 10px;
}

.NftModalFooterSubText {
  font-size: 100%;
  margin-top: 0px;
  margin-left: 10px;
}

.NftModalFooterTextLink {
  font-size: 100%;
  color: #fff;
  margin-left: 10px;
}

.NftModalInner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.PropertiesDiv {
  width: 140px;
  height: 100px;
  background-color: rgba(20, 100, 131, 0.2);
  border-style: solid;
  border-width: 2px;
  border-color: hsla(197, 74%, 30%, 0.8);
  border-radius: 8px;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PropertiesTitle {
  margin: 3px;
  font-size: 80%;
  font-weight: 500;
  text-align: center;
}

.PropertiesText {
  margin: 3px;
  font-weight: 400;
  color: #113d6a;
  text-align: center;
}

.PropertiesSubText {
  margin: 3px;
  font-size: 65%;
  font-weight: 500;
  text-align: center;
  text-align: center;
}

.NftModalImage {
  width: 100%;
}

.NftModalImageHide {
  width: 100%;
  display: none;
}

.NftModalGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.ScoreCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.ScoreCardLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
}

.ScoreCardRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
}

.RabbitIconDiv {
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: 20px;
  width: 100%;
  height: 70px;
  color: #fff;
  cursor: pointer;
}

.RabbitIcon {
  width: 40px;
  margin-bottom: -10px;
}

.TypingText {
  color: lightgray;
  margin: 30px;
  font-size: 120%;
  text-align: center;
}

.TooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 15px;
}

.TooltipHeaderInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.TooltipHeaderText {
  margin: 3px;
  font-weight: 600;
  color: #c7c7c7;
  font-size: 130%;
  text-align: center;
}

.TooltipInner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.TooltipImage {
  width: 90px;
  padding: 3px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .BottomVawesFront {
    width: 150%;
  }
  .BottomVawesBack {
    width: 150%;
  }
}

@media only screen and (max-width: 600px) {
  .AppHeader {
    height: 10vh;
  }

  .AppBar {
    height: 10vh;
  }

  .ToolBar {
    height: 10vh;
  }

  .AppBody {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .AppBodyInner {
    height: 50%;
    width: 100%;
  }

  .AppBodyFirst {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
  }

  .AppBodyFirstInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 86%;
    max-width: 600px;
    margin-right: 0;
    margin-top: 0;
  }

  .AppBodyFirstText {
    margin-right: 0;
    text-align: center;
    font-size: 90%;
    margin-bottom: 0px;
  }

  .AppBodyFirstTitle {
    text-align: center;
    margin-top: 2%;
    font-size: 130%;
  }

  .AppBodySecond {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height: 45%;
    width: 100%;
  }

  .AppBodySecondInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }

  .BottomVawesFront {
    width: 200%;
  }
  .BottomVawesBack {
    width: 200%;
  }
  .CardTitle {
    font-size: 80%;
  }
  .PropertiesDiv {
    width: 120px;
    height: 90px;
  }
  .NftModalFooterText {
    font-size: 70%;
  }

  .NftModalFooterTextLink {
    font-size: 70%;
  }

  .NftModalFooterSubText {
  font-size: 70%;
}

  .TooltipImage {
    width: 70px;
  }
  .RabbitIconDiv {
  bottom: 25px;
  width: 100%;
  height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 70%;
}

.RabbitIcon {
  width: 25px;
  margin-bottom: -5px;
}
}
